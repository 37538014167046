import { takeEvery, put } from "redux-saga/effects";
import axios from "src/store/_shared/authAxios";

import store from "..";

import { selectsConstants, tenantsConstants } from "../constants";
import { loadedSelects, loadedPrizeTemplates } from "../actions/selects";
import { loadedTagsTree } from "../actions/tags-tree";

import { buildApiUrl, getPlayerPrizesApiBaseUrl } from "../../utils/api";
import { accessPages, operationsAccess } from "../../utils/users";

const selectsApiUrl = buildApiUrl("/admin/bonuses/selects");
const tagsTreeApiUrl = buildApiUrl("/admin/audit_log/fetchTagsTree");

function* loadSelectsSaga(action) {
  const opAccessBonusesSelect = operationsAccess(accessPages.BONUSES_SELECTS);
  const opAccessAuditLog = operationsAccess(accessPages.AUDIT_LOGS);

  try {
    if (opAccessBonusesSelect.canList) {
      const { data } = yield axios.get(`${selectsApiUrl}`);
      yield put(loadedSelects(data));
    }
  } catch (e) {
    console.log("[SAGA] loadSelectsSaga selects error", e.toString());
  }

  try {
    if (opAccessAuditLog.canList) {
      const { data } = yield axios.get(`${tagsTreeApiUrl}`);

      yield put(loadedTagsTree(data));
    }
  } catch (e) {
    console.log("[SAGA] loadSelectsSaga tagsTree error", e.toString());
  }
}

let loadingForTenant = null;
let ts = null;

function* loadPrizeTemplatesSaga(action) {
  const opAccess = operationsAccess(accessPages.PLATFORM_PRIZE_TEMPLATE);

  const { tenants, games } = store.getState();
  let currentTenant = tenants.currentTenant || '2';
  if (action.tenantId) {
    currentTenant = action.tenantId;
  }
  
  currentTenant = currentTenant.toString();
  
  const now = new Date().getTime();
  
  if (currentTenant && loadingForTenant === currentTenant) {
    if (now - ts < 500) return;
  }
  
  // console.log("[SAGA] loadGamesSaga", currentTenant, games.tenant_id, now, now - ts);
  
  loadingForTenant = currentTenant;
  ts = now;

  try {
    if (opAccess.canList) {
      const { data } = yield axios.post(getPlayerPrizesApiBaseUrl("/admin/template/list"), {
        tenant_id: [0, currentTenant],
        limit: 65000,
        fields: ["id", "internal_name"],
      });
      yield put(loadedPrizeTemplates(data.data));
    }
  } catch (e) {
    console.log("[SAGA] loadPrizeTemplatesSaga error", e.toString());
  }
}

export default function* watchSelectsSaga() {
  //console.log("[SAGA] watching select");
  yield takeEvery(selectsConstants.LOAD, loadSelectsSaga);
  yield takeEvery(selectsConstants.LOAD, loadPrizeTemplatesSaga);
  yield takeEvery(tenantsConstants.SET_CURRENT_TENANT, loadPrizeTemplatesSaga);
}
