export const buildApiUrl = url => {
  if (import.meta.env.DEV) {
    return "/adminapi" + url;
  }
  return window.ini.REACT_APP_API_BASE_URL + url;
};

export const getCashierApiBaseUrl = () => {
  if (import.meta.env.DEV) {
    //return "http://localhost:5001";
  }
  return window.ini.REACT_APP_CASHIER_API_URL;
};

export const getTmplApiBaseUrl = (url = '') => {
  return window.ini.REACT_APP_TEMPLATIZATION_API + url;
};

export const getDataApiBaseUrl = url => {
  // if (import.meta.env.DEV) {
  //   return "http://localhost:5003/data/v1/" + url;
  // }
  return window.ini.REACT_APP_DATA_API_URL + url;
};

export const getContentManagementApiBaseUrl = url => {
  return window.ini.REACT_APP_CONTENT_MANAGEMENT_API_URL + url;
};

export const getGeneralSettingsApiBaseUrl = url => {
  return window.ini.REACT_APP_ACL_CONFIGURATION_API_URL + url;
};

export const getGeneralPlatformAttributesApiBaseUrl = url => {
  if (import.meta.env.DEV) {
    //return "http://localhost:5005/api/wl-platform/v1/" + url;
  }
  return window.ini.REACT_APP_PLATFORM_ATTRIBUTES_API_URL + url;
};

export const getLoyaltyApiBaseUrl = url => {
  return window.ini.REACT_APP_LOYALTY_API_URL + url;
};

export const getTranslationApiBaseUrl = url => {
  return window.ini.REACT_APP_TRANSLATION_API_URL + url;
};

export const getJackpotApiBaseUrl = url => {
  if (import.meta.env.DEV) {
    return "http://127.0.0.1:8666" + url;
  }
  return window.ini.REACT_APP_JACKPOT_API_URL + url;
};

export const getAutomationApiBaseUrl = url => {
  return window.ini.REACT_APP_AUTOMATION_API_URL + url;
};

export const getNotificationsApiBaseUrl = url => {
  return window.ini.REACT_APP_NOTIFICATIONS_API_URL + url;
};

export const getWLSetupApiBaseUrl = url => {
  return window.ini.REACT_APP_WL_SETUP_API_URL + url;
};

export const getACLConfiguationApiBaseUrl = url => {
  return window.ini.REACT_APP_ACL_CONFIGURATION_API_URL + url;
};

export const getPlayerApiBaseUrl = url => {
  return window.ini.REACT_APP_PLAYER_API_URL + url;
};

export const statScoreLiveAPI =
  "https://api.statscore.com/v2/booked-events?product=livescorepro&events_details=no&client_id=635&mapped_status=mapped&client_event_id=";
export const statScorePreMatchAPI =
  "https://api.statscore.com/v2/booked-events?product=prematchcenter&events_details=no&mapped_status=mapped&client_id=635&client_event_id=";

export const statScoreLiveURL = idMatch => {
  return `${statScoreLiveAPI}${idMatch}`;
};

export const statScorePreMatchURL = idMatch => {
  return `${statScorePreMatchAPI}${idMatch}`;
};

export const winnerFunPlayerUrl = () => {
  if (false && import.meta.env.DEV) {
    return "http://localhost:8002";
  }

  return window.ini.REACT_APP_WINNER_FUN_URL;
};

export const marketingEventProcessorUrl = url => {
  if (import.meta.env.DEV) {
    // return "http://localhost:5002/marketing-event-processor/v1/admin" + url;
  }

  return window.ini.REACT_APP_MARKETING_EVENT_PROCESSOR_API_URL + url;
};

export const getPlayerPrizesApiBaseUrl = url => {
  if (import.meta.env.DEV) {
    // return "http://localhost:8668/api/prizes/v1" + url;
  }

  return window.ini.REACT_APP_PLAYER_PRIZES_API_URL + url;
};

export const getRewardBundlesApiBaseUrl = url => {
  return window.ini.REACT_APP_REWARD_BUNDLES_API_URL + url;
};

export const getLimitsApiBaseUrl = url => {
  return window.ini.REACT_APP_LIMITS_API_URL + url;
};

export const getSuper8ApiBaseUrl = url => {
  return window.ini.REACT_APP_SUPER8_API_URL + url;
};
