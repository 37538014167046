import React from "react";
import CommandPaletteBase from "react-command-palette";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Search, X } from "react-feather";

import "./CommandPalette.scss";
import getHistory from "../history";
import { operationsAccess, accessPages } from "../utils/users";
import { hasAclAccess } from "../utils/acl";

const rootPath = window.ini.REACT_APP_ROOT_PATH;

export const pages = [
  // TODO: change all accessPages with accessPages

  "",
  accessPages.HOME,
  `${rootPath}/home`,
  "Home",

  "Risk & Performance",
  accessPages.RISK_PERFORMANCE_ALERTS,
  `${rootPath}/risk-performance-monitor/alerts`,
  "Alerts",

  "Risk & Performance",
  accessPages.RISK_PERFORMANCE_AUDIT_LOGS, // TODO: check
  `${rootPath}/audit_logs`,
  "Audit Logs",

  "Risk & Performance",
  accessPages.RISK_PERFORMANCE_DUE_DILIGENCE,
  `${rootPath}/risk-performance-monitor/due-diligence`,
  "Due Diligence Queue",

  "Risk & Performance",
  accessPages.RISK_PERFORMANCE_DOCUMENTS,
  `${rootPath}/risk-performance-monitor/documents-verification-queue`,
  "Docs Verification Queue",

  "Risk & Performance",
  "tenant::customer_accounts_documents_list",
  `${rootPath}/player/documents-search`,
  "Player Documents",

  "Risk & Performance",
  "tenant::customer_accounts_login_history_list",
  `${rootPath}/player/login-history-search`,
  "Player Login History",

  "Risk & Performance",
  accessPages.RISK_PERFORMANCE_CLEAR_CLOSED_ACCOUNTS,
  `${rootPath}/risk-performance-monitor/clear-closed-accounts`,
  "Clear Closed Accounts",

  "Bulk Tools",
  accessPages.BULK_TOOLS_DEDUCTION,
  `${rootPath}/bulk-tools/wallet-cleanup`,
  "Wallet Cleanup",

  "Bulk Tools",
  accessPages.BULK_TOOLS_GRANT_BONUS,
  `${rootPath}/bulk-tools/grant-bonus`,
  "Grant Bonus",

  "Bulk Tools",
  accessPages.BULK_TOOLS_GRANT_PLAYER_TAGS,
  `${rootPath}/bulk-tools/grant-player-tags`,
  "Grant Player Tags",

  "Bulk Tools",
  accessPages.PLAYER_AFFILIATE_INFO,
  `${rootPath}/bulk-tools/grant-player-tags`,
  "Update Affiliate Info",

  "ACL",
  "tenant::list",
  `${rootPath}/acl/tenants`,
  "Tenants",

  "ACL",
  "location::list",
  `${rootPath}/acl/locations`,
  "Locations",

  "ACL",
  "site::list",
  `${rootPath}/acl/domains`,
  "Domains",

  "ACL",
  "site::list",
  `${rootPath}/acl/sites`,
  "Sites",

  "ACL",
  "api_token::list",
  `${rootPath}/acl/api-tokens`,
  "Api Tokens",

  "ACL",
  "acl_entity_group::list",
  `${rootPath}/acl/entity-groups`,
  "Entity Groups",

  "ACL",
  "acl_user::list",
  `${rootPath}/acl/users`,
  "Users List",

  "ACL",
  "acl_user_group::list",
  `${rootPath}/acl/groups`,
  "Users Groups",

  "ACL",
  "acl_permission::list",
  `${rootPath}/acl/permissions`,
  "Permissions",

  "ACL",
  "acl_role::list",
  `${rootPath}/acl/roles`,
  "Roles",

  "ACL",
  "acl_rule::list",
  `${rootPath}/acl/rules`,
  "Rules",

  "Templatization",
  accessPages.DATA_SOURCES_DATA_ELEMENT,
  `${rootPath}/data/data-element-list`,
  "Data Element",

  "Templatization",
  accessPages.DATA_SOURCES_DATA_ELEMENT_TEMPLATE,
  `${rootPath}/data/data-element-template-list`,
  "Data Element Template",

  "Templatization",
  accessPages.DATA_SOURCES_DATA_SOURCE,
  `${rootPath}/data/data-source-list`,
  "Data Source",

  "Templatization",
  accessPages.DATA_SOURCES_DATA_SOURCE,
  `${rootPath}/data/data-source-reducers-list`,
  "Data Sources Reducers List",

  "Templatization",
  accessPages.DATA_SOURCES_DATA_SOURCE,
  `${rootPath}/data/data-source-partial-list`,
  "Data Source Partial",

  "Automation",
  accessPages.AUTOMATION_TASKS,
  `${rootPath}/admin/automation/tasks`,
  "Tasks",

  "Automation",
  accessPages.ADMIN_NOTIFICATIONS,
  `${rootPath}/admin/notifications/list`,
  "Admin Notifications List",

  "Automation",
  accessPages.ADMIN_NOTIFICATIONS_SUBSCRIPTIONS,
  `${rootPath}/admin/notifications/subscriptions`,
  "Admin Notifications Subscriptions",

  "Translations",
  accessPages.MULTI_LANGUAGES_LANGUAGES,
  `${rootPath}/admin/automation/languages`,
  "Languages",

  "Translations",
  accessPages.MULTI_LANGUAGES_PROJECTS,
  `${rootPath}/admin/automation/projects`,
  "Projects",

  "Translations",
  accessPages.MULTI_LANGUAGES_TEXTS,
  `${rootPath}/admin/automation/texts`,
  "Texts",

  "Translations",
  accessPages.MULTI_LANGUAGES_TRANSLATIONS,
  `${rootPath}/admin/automation/translations`,
  "Translations",

  "Customer Accounts",
  accessPages.CUSTOMER_ACCOUNTS_PLAYER_SEARCH,
  `${rootPath}/players/search`,
  "Player Search",

  "Customer Accounts",
  accessPages.CUSTOMER_ACCOUNTS_BONUS_SEARCH,
  `${rootPath}/player-bonus/search`,
  "Bonus Search",

  "Customer Accounts",
  accessPages.CUSTOMER_ACCOUNTS_TRANSACTIONS_SEARCH,
  `${rootPath}/transactions/search`,
  "Transactions Search",

  "Customer Accounts",
  accessPages.CUSTOMER_ACCOUNTS_PARTIAL_ACCOUNTS,
  `${rootPath}/partial-accounts/search`,
  "Partial Accounts",

  "Customer Accounts",
  accessPages.CUSTOMER_ACCOUNTS_MANAGE_SOCIAL_NUMBER,
  `${rootPath}/manage-social-number`,
  "Manage Social Number",

  "Payments",
  accessPages.FINANCIAL_IN_OUT_SEARCH,
  `${rootPath}/payments/search`,
  "Payments Search",

  "Payments",
  accessPages.FINANCIAL_IN_OUT_TAX_REPORT,
  `${rootPath}/payments/tax-report`,
  "Tax Report",

  "ONJN Reports",
  accessPages.ONJN_REPORT,
  `${rootPath}/onjn/relevant-events/search`,
  "Relevant Events",

  "ONJN Reports",
  accessPages.ONJN_REPORT,
  `${rootPath}/onjn/game-type/search`,
  "Game Type",

  "ONJN Reports",
  accessPages.ONJN_REPORT,
  `${rootPath}/onjn/game-profit/search`,
  "Game Profit",

  "ONJN Reports",
  accessPages.ONJN_REPORT,
  `${rootPath}/onjn/game-sessions/search`,
  "Game Sessions",

  "ONJN Reports",
  accessPages.ONJN_REPORT,
  `${rootPath}/onjn/jackpot-winnings/search`,
  "Jackpot Winnings",

  "ONJN Reports",
  accessPages.ONJN_REPORT,
  `${rootPath}/onjn/exclusion-report/search`,
  "Exclusion Report",

  "ONJN Reports",
  accessPages.ONJN_REPORT,
  `${rootPath}/onjn/wallet-adjustment/search`,
  "Wallet Adjustment",

  "ONJN Reports",
  accessPages.ONJN_REPORT,
  `${rootPath}/onjn/promotions/search`,
  "Promotions",

  "ONJN Reports",
  accessPages.ONJN_REPORT,
  `${rootPath}/onjn/report-export/search`,
  "Report of Export",

  "ONJN Reports",
  accessPages.ONJN_REPORT,
  `${rootPath}/onjn/requested-reports/search`,
  "Report Requests",

  "ONJN Reports",
  accessPages.ONJN_REPORT,
  `${rootPath}/onjn/cron-logs/search`,
  "Cron Logs",

  "Payments",
  accessPages.FINANCIAL_IN_OUT_WITHDRAW_ACTIONS,
  `${rootPath}/payments/withdrawals`,
  "Withdrawals",

  "Viva",
  accessPages.VIVA_WITHDRAWAL,
  `${rootPath}/viva/withdrawal`,
  "Withdrawal",

  "Viva",
  accessPages.VIVA_WITHDRAWAL_LOG,
  `${rootPath}/viva/logs-withdrawal`,
  "Withdrawal Logs",

  "Viva",
  accessPages.VIVA_DEPOSITS,
  `${rootPath}/viva/deposits`,
  "Deposits",

  "Cashier",
  accessPages.CASHIER,
  `${rootPath}/cashier/sessions`,
  "Sessions",

  "Cashier",
  accessPages.CASHIER,
  `${rootPath}/cashier/admin-deposit`,
  "Admin Deposit",

  "Retail",
  accessPages.RETAIL_TICKETS_MANAGEMENT,
  `${rootPath}/retail/tickets`,
  "Retail Tickets",

  "Retail",
  accessPages.RETAIL_TICKETS_MANAGEMENT,
  `${rootPath}/retail/settings/0`,
  "Retail Tickets Settings",

  "Evolution",
  accessPages.EVOLUTION_CAMPAIGNS,
  `${rootPath}/evolution/campaigns`,
  "Campaigns",

  "Players",
  accessPages.PLAYERS_PLAYER_NICKNAMES,
  `${rootPath}/social/players`,
  "Nicknames",

  "Players",
  accessPages.PLAYERS_AVATARS,
  `${rootPath}/social/avatars`,
  "Avatars",

  "Players",
  accessPages.PLAYERS_AVATARS_DEFINITIONS,
  `${rootPath}/social/definitions-avatars/0`,
  "Avatars Definitions",

  "Players",
  accessPages.PLAYERS_SOCIAL_MANAGEMENT,
  `${rootPath}/social/management`,
  "Social Management",

  "Prizes",
  accessPages.PLATFORM_PRIZE_TEMPLATE,
  `${rootPath}/prizes/prize-templates`,
  "Prize Templates",

  "Loyalty",
  accessPages.PLATFORM_REWARD_BUNDLE,
  `${rootPath}/loyalty/reward-bundles`,
  "Reward Bundles",

  // TODO: permissions for Jackpots

  "Momentum",
  accessPages.MOMENTUM_SETTINGS,
  `${rootPath}/momentum/settings`,
  "Momentum Settings",

  "Momentum",
  accessPages.REGISTRY,
  `${rootPath}/momentum/binds`,
  "Momentum Binds",

  "Momentum",
  accessPages.MOMENTUM_AWARDS,
  `${rootPath}/momentum/awards-list`,
  "Momentum Awards List",

  "Missions",
  accessPages.MISSIONS_MISSIONS_RANKINGS,
  `${rootPath}/missions-tournaments/rankings?tenant=[CURRENT-TENANT]`,
  "Missions Rankings",

  "Missions",
  accessPages.MISSIONS_OBJECTIVES_TEMPLATES,
  `${rootPath}/missions-tournaments/templates-objectives?tenant=[CURRENT-TENANT]`,
  "Objectives Templates",

  "Missions",
  accessPages.MISSIONS_MISSIONS_TEMPLATES,
  `${rootPath}/missions-tournaments/templates-missions?tenant=[CURRENT-TENANT]`,
  "Missions Templates",

  "Missions",
  accessPages.MISSIONS_OBJECTIVES,
  `${rootPath}/missions-tournaments/objectives?tenant=[CURRENT-TENANT]`,
  "Objectives",

  "Missions",
  accessPages.MISSIONS_MISSIONS,
  `${rootPath}/missions-tournaments/missions?tenant=[CURRENT-TENANT]`,
  "Missions",

  // /missions-tournaments/tournament-challenge-groups
  "Missions",
  accessPages.MISSIONS_TOURNAMENT_GROUPS,
  `${rootPath}/missions-tournaments/tournament-challenge-groups?type=1`,
  "Tourn. Challenge Groups",

  "Missions",
  accessPages.CHALLENGES_PLAYER_MISSIONS,
  `${rootPath}/missions-tournaments/player-missions?tenant=[CURRENT-TENANT]`,
  "Player Missions",

  "Missions",
  accessPages.MISSIONS_TOURNAMENT_GROUPS,
  `${rootPath}/missions-tournaments/tournament-bonus-withdrawal-groups?type=6`,
  "Tourn. Bonus Withdrawal Missions",

  "Missions",
  accessPages.MISSIONS_TOURNAMENT_GROUPS,
  `${rootPath}/missions-tournaments/tournament-casino-groups?tenant=[CURRENT-TENANT]&type=2`,
  "Tourn. Casino Groups",

  "Missions",
  accessPages.MISSIONS_TOURNAMENT_GROUPS,
  `${rootPath}/missions-tournaments/tournament-sport-groups?tenant=[CURRENT-TENANT]&type=3`,
  "Tourn. Sport Groups",

  "Landing Pages",
  accessPages.LANDING_PAGES_MARKETING_OFFERS,
  `${rootPath}/landing/marketing-offerings`,
  "Marketing Offers",

  "Landing Pages",
  accessPages.LANDING_PAGES_PAGES,
  `${rootPath}/landing/pages/0`,
  "Pages",

  "Promo",
  accessPages.PROMO_CAMPAIGN_SETTINGS,
  `${rootPath}/promo/campaign-settings/0`,
  "Campaign Settings",

  "Promo",
  accessPages.PROMO_WINTER_PROMO,
  `${rootPath}/promo/winter`,
  "Winter Promo",

  "Promo",
  accessPages.PROMO_WINTER_PRIZES,
  `${rootPath}/promo/prizes-winter`,
  "Winter Prizes",

  "Lobby",
  accessPages.LOBBY_DATA_SOURCES,
  `${rootPath}/lobby/data-sources/0`,
  "Data Sources",

  "Lobby",
  accessPages.LOBBY_PAGES,
  `${rootPath}/lobby/pages/0`,
  "Pages",

  "Wheel",
  accessPages.WHEEL_GENERAL_SETTINGS,
  `${rootPath}/wheel/settings/0`,
  "General Settings",

  "Wheel",
  accessPages.WHEEL_CORE_ELIGIBILITY,
  `${rootPath}/wheel/core-eligibility`,
  "Core Eligibility",

  "Wheel",
  accessPages.WHEEL_PRODUCTS,
  `${rootPath}/wheel/products/0`,
  "Products",

  "Wheel",
  accessPages.WHEEL_VALUES,
  `${rootPath}/wheel/values/0`,
  "Values",

  "Wheel",
  accessPages.WHEEL_MULTIPLIERS,
  `${rootPath}/wheel/multipliers/0`,
  "Multipliers",

  "Wheel",
  accessPages.WHEEL_PUZZLES,
  `${rootPath}/wheel/puzzles/0`,
  "Puzzles",

  "Wheel",
  accessPages.WHEEL_PRIZES,
  `${rootPath}/wheel/prizes/0`,
  "Prizes",

  "Wheel",
  accessPages.WHEEL_WHEEL_SETUPS,
  `${rootPath}/wheel/setups/0`,
  "Wheel Setups",

  "Wheel",
  accessPages.WHEEL_PLAYER_LEVELS,
  `${rootPath}/wheel/player-level-setups/0`,
  "Player Levels",

  "Wheel",
  accessPages.WHEEL_RNG_RANGES,
  `${rootPath}/wheel/probability-ranges`,
  "RNG Ranges",

  "Wheel",
  accessPages.WHEEL_PLAYER_WHEEL_SPINS,
  `${rootPath}/wheel/player-wheel-spins`,
  "Wheel Spins",

  "Wheel",
  accessPages.WHEEL_AGG_WHEEL_SPINS,
  `${rootPath}/wheel/agg-wheel-spins`,
  "Agg. Wheel Spins",

  "Wheel",
  accessPages.WHEEL_PLAYER_KEYS,
  `${rootPath}/wheel/player-wheel-keys`,
  "Player Keys",

  "Maintenance",
  accessPages.MAINTENANCE,
  `${rootPath}/maintenance/0`,
  "Maintenance",

  "Bonuses",
  accessPages.BONUSES_CLASSES_TAGS,
  `${rootPath}/bonuses/tags`,
  "Classes - Tags",

  "Bonuses",
  accessPages.BONUSES_BONUS_PRESET,
  `${rootPath}/bonuses/bonus-presets`,
  "Bonus Preset",

  "Bonuses",
  accessPages.BONUSES_ENROLLMENT_CAMPAIGNS,
  `${rootPath}/bonuses/enrollment-campaigns`,
  "Enrl. Campaigns",

  "Bonuses",
  accessPages.BONUSES_CAMPAIGNS,
  `${rootPath}/bonuses/campaigns`,
  "Campaigns",

  "Bonuses",
  accessPages.BONUSES_BONUS_POPUPS,
  `${rootPath}/bonuses/popups`,
  "Bonus Popups",

  // TODO : add Loyalty permissions

  // /loyalty/currency-groups
  "Loyalty",
  "tenant::todo_tenant",
  // accessPages.LOYALTY_CURRENCY_GROUPS,
  `${rootPath}/loyalty/currency-groups`,
  "Currency Groups",

  // /loyalty/currencies
  "Loyalty",
  "tenant::todo_tenant",
  // accessPages.LOYALTY_CURRENCIES,
  `${rootPath}/loyalty/currencies`,
  "Currencies",

  // /loyalty/sort-currency-groups
  "Loyalty",
  "tenant::todo_tenant",
  // accessPages.LOYALTY_SORT_CURRENCY_GROUPS,
  `${rootPath}/loyalty/sort-currency-groups`,
  "Sort Currency Groups",

  // /loyalty/campaign-groups
  "Loyalty",
  "tenant::todo_tenant",
  // accessPages.LOYALTY_CAMPAIGN_GROUPS,
  `${rootPath}/loyalty/campaign-groups`,
  "Campaign Groups",

  // /loyalty/campaigns
  "Loyalty",
  "tenant::todo_tenant",
  // accessPages.LOYALTY_CAMPAIGNS,
  `${rootPath}/loyalty/campaigns`,
  "Campaigns",

  // /loyalty/rank-campaign-groups
  "Loyalty",
  "tenant::todo_tenant",
  // accessPages.LOYALTY_RANK_CAMPAIGN_GROUPS,
  `${rootPath}/loyalty/rank-campaign-groups`,
  "Rank Campaign Groups",

  // /loyalty/rank-campaigns
  "Loyalty",
  "tenant::todo_tenant",
  // accessPages.LOYALTY_RANK_CAMPAIGNS,
  `${rootPath}/loyalty/rank-campaigns`,
  "Rank Campaigns",

  // /loyalty/missions
  "Loyalty",
  "tenant::todo_tenant",
  // accessPages.LOYALTY_MISSIONS,
  `${rootPath}/loyalty/missions`,
  "Missions",

  // /loyalty/objectives
  "Loyalty",
  "tenant::todo_tenant",
  // accessPages.LOYALTY_OBJECTIVES,
  `${rootPath}/loyalty/objectives`,
  "Objectives",
  // ****************************************************

  "Optimove",
  accessPages.OPTIMOVE_CAMPAIGN_TAGS,
  `${rootPath}/optimove/campaign-tags`,
  "Campaign Tags",

  "Optimove",
  accessPages.OPTIMOVE_SCHEDULED_RUNS,
  `${rootPath}/optimove/scheduled-runs`,
  "Scheduled Runs",

  "Optimove",
  accessPages.OPTIMOVE_TAGGED_PLAYERS,
  `${rootPath}/optimove/tagged-players`,
  "Tagged Players",

  "Winner Fun",
  accessPages.WINNER_FUN_CALENDAR,
  `${rootPath}/winner-fun/calendar/0`,
  "Calendar",

  "Winner Fun",
  accessPages.WINNER_FUN_WIN_COINS,
  `${rootPath}/winner-fun/win-coins-systems/0`,
  "Win Coins",

  "Winner Fun",
  accessPages.WINNER_FUN_PLAYERS,
  `${rootPath}/winner-fun/players`,
  "Players",

  "Winner Fun",
  accessPages.WINNER_FUN_REFERRAL_SETTINGS,
  `${rootPath}/winner-fun/referral-settings/0`,
  "Referral Settings",

  "Winner Fun",
  accessPages.WINNER_FUN_SUPER_BANNER,
  `${rootPath}/winner-fun/super-banner/0`,
  "Super Banner",

  "Winner Fun",
  accessPages.WINNER_FUN_CLAIM_REPORT,
  `${rootPath}/winner-fun/claim-report`,
  "Claim Report",

  "Winner Fun",
  accessPages.WINNER_FUN_RAF_REPORT,
  `${rootPath}/winner-fun/raf-report`,
  "RAF Report",

  "Winner Fun",
  accessPages.WINNER_FUN_RANKING_SETTINGS,
  `${rootPath}/winner-fun/ranking-settings/0`,
  "Ranking Settings",

  "Winner Fun",
  accessPages.WINNER_FUN_TEAMS_UI,
  `${rootPath}/winner-fun/teams-ui/0`,
  "Teams UI",

  "Winner Fun",
  accessPages.WINNER_FUN_TEAMS_LIST,
  `${rootPath}/winner-fun/teams-list/0`,
  "Teams List",

  "Messages & Notifications",
  accessPages.MESSAGES_NOTIFICATIONS_NEW_MESSAGE,
  `${rootPath}/comms/new-message`,
  "New Message",

  "Messages & Notifications",
  accessPages.MESSAGES_NOTIFICATIONS_PLAYER_MESSAGES,
  `${rootPath}/comms/player-messages`,
  "Player Messages",

  "Messages & Notifications",
  accessPages.MESSAGES_NOTIFICATIONS_NOTIFICATIONS,
  `${rootPath}/comms/notifications`,
  "Notifications",

  "Casino",
  accessPages.CASINO_SUPER_BANNER,
  `${rootPath}/casino-super-banner/0`,
  "Supper Banner",

  "Casino",
  accessPages.CASINO_HOMEPAGE,
  `${rootPath}/homepage-components/0`,
  "Homepage",

  "Casino",
  accessPages.CASINO_GAMES,
  `${rootPath}/casino/games`,
  "Games",

  "Casino",
  accessPages.CASINO_HOMEPAGE,
  `${rootPath}/mobile-app/views`,
  "Mobile App Views",

  "Casino",
  accessPages.CASINO_GAMES_ATTRIBUTES,
  `${rootPath}/casino/attributes-games/0`,
  "Games Attributes",

  "Casino",
  accessPages.DATA_SOURCES_DATA_SOURCE,
  `${rootPath}/casino/data-sources-games`,
  "Data Sources Games",

  "Bets - Homepage",
  accessPages.BETS_HOMEPAGE_HOMEPAGE_GROUPS,
  `${rootPath}/homepage-groups/0`,
  "Homepage Groups",

  "Bets - Homepage",
  accessPages.BETS_HOMEPAGE_H_GROUPS_IMAGES,
  `${rootPath}/homepage-groups-images/0`,
  "H. Groups Images",

  "Bets - Homepage",
  accessPages.BETS_HOMEPAGE_SPORTS_NAVBAR,
  `${rootPath}/homepage-sports-navbar/0`,
  "Sports NavBar",

  "Bets - Homepage",
  accessPages.BETS_HOMEPAGE_LEAGUES,
  `${rootPath}/leagues/0`,
  "Leagues",

  "Bets - Homepage",
  accessPages.BETS_HOMEPAGE_NEWS,
  `${rootPath}/news/0`,
  "News",

  "Bets",
  accessPages.BETS_SPORTS,
  `${rootPath}/sports/0`,
  "Sports",

  "Bets",
  accessPages.BETS_MARKETS_SORTER,
  `${rootPath}/markets-groups-sorter/0`,
  "Markets Groups Sorter",

  "Bets",
  accessPages.UK_SETTINGS_MARKETS,
  `${rootPath}/uk-settings-markets/0`,
  "UK Settings Markets",

  "Bets",
  accessPages.BETS_HIGHLIGHTS,
  `${rootPath}/highlights/0`,
  "Highlights",

  "Bets",
  accessPages.BETS_CALENDAR_HOT_DAYS,
  `${rootPath}/calendar-hot/0`,
  "Calendar (Hot Days)",

  "Bets",
  accessPages.BETS_LIVE_TOP_LEAGUE,
  `${rootPath}/live-top-league/0`,
  "Live Top League",

  "Bets",
  accessPages.BETS_SETTINGS,
  `${rootPath}/settings/0`,
  "Settings",

  "Bets",
  accessPages.BETS_CUSTOMIZE_NAVBAR,
  `${rootPath}/customize-navbar/0`,
  "Customize Navbar",

  "Nsoft",
  accessPages.NSOFT_CRESTS,
  `${rootPath}/crests/0`,
  "Crests",

  "Nsoft",
  accessPages.NSOFT_ENTITIES,
  `${rootPath}/entities/0`,
  "Entities",

  "Nsoft",
  accessPages.NSOFT_BETS,
  `${rootPath}/bets/0`,
  "Bets",

  "Nsoft",
  accessPages.NSOFT_OUTCOMES,
  `${rootPath}/outcomes/0`,
  "Outcomes",

  "Nsoft",
  accessPages.NSOFT_SPORT_RADAR,
  `${rootPath}/sr/0`,
  "Sport Radar",

  "Bets Bonus",
  accessPages.BETS_BONUS_TICKET_BUILDER,
  `${rootPath}/bonus-ticket-builder`,
  "Ticket Builder",

  "Bets Bonus",
  accessPages.BETS_BONUS_BONUS_TICKET,
  `${rootPath}/bonus-ticket-list`,
  "Bonus Ticket List",

  "Bets Bonus",
  accessPages.BETS_BONUS_BONUS_RULES,
  `${rootPath}/bonus-rules-list`,
  "Bonus Rules List",

  "Bets Bonus",
  accessPages.BETS_BONUS_OPENED_TICKETS,
  `${rootPath}/bonus-tickets-opened-list`,
  "Opened Tickets List",

  "Bets Bonus",
  accessPages.BETS_BONUS_SETTLED_TICKETS,
  `${rootPath}/bonus-tickets-settled-list`,
  "Settled Tickets List",

  "Happy Hours",
  accessPages.HAPPY_HOURS_DEFINITIONS,
  `${rootPath}/happy-hours-definitions/0`,
  "Definitions",

  "Happy Hours",
  accessPages.HAPPY_HOURS_EVENTS,
  `${rootPath}/happy-hours-events/0`,
  "Events",

  "Happy Hours",
  accessPages.HAPPY_HOURS_AWARDS,
  `${rootPath}/happy-hours-awards`,
  "Awards",

  "Calendar",
  accessPages.CALENDAR_CAMPAIGNS,
  `${rootPath}/calendar/campaigns`,
  "Campaigns",

  "Calendar",
  accessPages.CALENDAR_PRIZES,
  `${rootPath}/calendar/prizes`,
  "Prizes",

  "Calendar",
  accessPages.CALENDAR_PLAYERS_CAMPAIGN_DATA,
  `${rootPath}/calendar/players-campaign-data`,
  "Players Campaign Data",

  "Calendar",
  accessPages.CALENDAR_PLAYERS_PRIZE_DATA,
  `${rootPath}/calendar/players-prize-data`,
  "Players Prize Data",

  "Calendar",
  accessPages.CALENDAR_PLAYERS_OPERATIONS,
  `${rootPath}/calendar/players-operations`,
  "Players Operations",

  "Airdrop",
  accessPages.AIRDROP_CAMPAIGNS,
  `${rootPath}/airdrop/campaigns`,
  "Campaigns",

  "Airdrop",
  accessPages.AIRDROP_ITEMS,
  `${rootPath}/airdrop/items`,
  "Items",

  "Airdrop",
  accessPages.AIRDROP_PLAYERS_CAMPAIGN_DATA,
  `${rootPath}/airdrop/campaigns-item-data`,
  "Campaigns Item Data",

  "Airdrop",
  accessPages.AIRDROP_PLAYERS_ITEM_DATA,
  `${rootPath}/airdrop/players-item-data`,
  "Players Item Data",

  "Airdrop",
  accessPages.AIRDROP_PLAYERS_OPERATIONS,
  `${rootPath}/airdrop/players-operations`,
  "Players Operations",

  "Lotto",
  accessPages.LOTTO_SUPER_BANNER,
  `${rootPath}/lotto-super-banner/0`,
  "Super Banner",

  "Lotto",
  accessPages.LOTTO_LOTTO_PROMOTED,
  `${rootPath}/lotto-promoted/0`,
  "Lotto Promoted",

  "Lotto",
  accessPages.LOTTO_LOTTO_RASPBERRY_PI,
  `${rootPath}/lotto-rp-refresh`,
  "Lotto Raspberry PI",

  "Virtual Games",
  accessPages.VIRTUAL_GAMES_SUPPER_BANNER,
  `${rootPath}/virtual-super-banner/0`,
  "Supper Banner",

  "Error Log",
  accessPages.ERROR_LOG,
  `${rootPath}/error_log`,
  "Error Log",

  "Audit Log",
  accessPages.AUDIT_LOGS,
  `${rootPath}/audit_logs`,
  "Audit Logs",

  "Media Library",
  accessPages.MEDIA_LIBRARY,
  `${rootPath}/media-library`,
  "Media Library",

  "Video Reels",
  accessPages.MEDIA_LIBRARY,
  `${rootPath}/video-reels`,
  "Video Reels",

  "Affiliates Postback Tracking",
  accessPages.AFFILIATES_POSTBACK_TRACKING_NETWORK,
  `${rootPath}/affiliates/traffic-networks/list`,
  "Traffic Networks",

  "Affiliates Postback Tracking",
  accessPages.AFFILIATES_POSTBACK_TRACKING,
  `${rootPath}/affiliates/postback-tracking/list`,
  "Affiliates",

  "Content Management",
  accessPages.PLATFORM_CURRENCY,
  `${rootPath}/general-settings/currency`,
  "Currency List",

  "Content Management",
  accessPages.PLATFORM_COUNTRY,
  `${rootPath}/general-settings/country`,
  "Country List",

  "Content Management",
  accessPages.PLATFORM_GEO_REGION,
  `${rootPath}/general-settings/geo-region`,
  "Geo Region List",

  "Content Management",
  accessPages.PLATFORM_CASINO_GAME_PROVIDER,
  `${rootPath}/general-settings/game-provider`,
  "Game Provider List",

  "Content Management",
  accessPages.PLATFORM_CASINO_GAME,
  `${rootPath}/general-settings/casino-game`,
  "Casino Game List",

  "Content Management",
  accessPages.PLATFORM_ATTRIBUTES,
  `${rootPath}/general-settings/attributes`,
  "Attributes List",

  "Limits",
  accessPages.PLATFORM_SCOPE_USAGE_LIMITS,
  `${rootPath}/general-settings/limits`,
  "Limits",

  "Instances",
  accessPages.PLATFORM_SCOPE_USAGE_INSTANCES,
  `${rootPath}/general-settings/instances`,
  "Instances",

  "Associations",
  accessPages.PLATFORM_SCOPE_USAGE_ASSOCIATIONS,
  `${rootPath}/general-settings/associations`,
  "Associations",

  "Content Management",
  accessPages.PLATFORM_ART_BUNDLE_TEMPLATE,
  `${rootPath}/general-settings/art-bundle-template`,
  "Art Bundle Templates List",

  "Content Management",
  accessPages.PLATFORM_ART_BUNDLE,
  `${rootPath}/general-settings/art-bundles`,
  "Art Bundles List",

  "Content Management",
  accessPages.PLATFORM_PRIZE_TEMPLATE,
  `${rootPath}/general-settings/prize-templates`,
  "Prize Templates List",

  "Templatization",
  accessPages.TEMPLATIZATION_CUSTOMIZATIONS,
  `${rootPath}/templatization/customizations`,
  "Customize Pages",

  "Content Management",
  // accessPages.DATA_SOURCES_DATA_ELEMENT, // TODO: permission ->  change to a proper permission
  "tenant::todo_tenant",
  `${rootPath}/general-settings/happy-hour/definitions/0`,
  "HappyHour Definitions List",

  "Content Management",
  accessPages.PLATFORM_JACKPOT_GROUP,
  `${rootPath}/general-settings/jackpot-group`,
  "Jackpot Groups List",

  "Content Management",
  accessPages.PLATFORM_BANNER,
  `${rootPath}/general-settings/banner`,
  "Banner List",

  "Content Management",
  accessPages.PLATFORM_BANNER_TEMPLATE,
  `${rootPath}/general-settings/template-banner`,
  "Banner Template List",

  "Content Management",
  accessPages.PLATFORM_PROMOTION,
  `${rootPath}/general-settings/promotion`,
  "Promotion List",

  "Content Management",
  accessPages.PLATFORM_PROMOTION_TEMPLATE,
  `${rootPath}/general-settings/template-promotion`,
  "Promotion Template List",

  "Content Management",
  accessPages.PLATFORM_SHOP_ITEM,
  `${rootPath}/general-settings/shop-item`,
  "Shop Item List",

  "Content Management",
  accessPages.PLATFORM_SHOP_ITEM_TEMPLATE,
  `${rootPath}/general-settings/template-shop-item`,
  "Shop Item Template List",

  "Content Management",
  accessPages.PLATFORM_BONUS_PRESET,
  `${rootPath}/general-settings/bonus-preset`,
  "Bonus Preset List",

  "Content Management",
  // accessPages.DATA_SOURCES_DATA_ELEMENT, // TODO: permission ->  change to a proper permission
  "tenant::todo_tenant",
  `${rootPath}/general-settings/objectives-templates`,
  "Missions Objectives List",

  "Content Management",
  // accessPages.DATA_SOURCES_DATA_ELEMENT, // TODO: permission ->  change to a proper permission
  "tenant::todo_tenant",
  `${rootPath}/general-settings/missions-templates`,
  "Missions Templates List",

  "Content Management",
  "tenant::todo_tenant",
  // accessPages.DATA_SOURCES_DATA_ELEMENT, // TODO: permission ->  change to a proper permission
  `${rootPath}/general-settings/wheel/setups-templates`,
  "Wheel Setups Templates List",

  "Content Management",
  "tenant::todo_tenant",
  // accessPages.DATA_SOURCES_DATA_ELEMENT, // TODO: permission ->  change to a proper permission
  `${rootPath}/general-settings/wheel/products-templates`,
  "Wheel Products Templates List",

  "Content Management",
  "tenant::todo_tenant",
  // accessPages.DATA_SOURCES_DATA_ELEMENT, // TODO: permission ->  change to a proper permission
  `${rootPath}/general-settings/wheel/values-templates`,
  "Wheel Values Templates List",

  "Content Management",
  "tenant::todo_tenant",
  // accessPages.DATA_SOURCES_DATA_ELEMENT, // TODO: permission ->  change to a proper permission
  `${rootPath}/general-settings/wheel/multipliers-templates`,
  "Wheel Multipliers Templates List",

  "Content Management",
  // accessPages.DATA_SOURCES_DATA_ELEMENT, // TODO: permission ->  change to a proper permission
  "tenant::todo_tenant",
  `${rootPath}/general-settings/wheel/puzzles-templates`,
  "Wheel Puzzles Templates List",

  "Content Management",
  accessPages.PLATFORM_BONUS,
  `${rootPath}/general-settings/bonus-list`,
  "Bonus List",

  "Content Management",
  accessPages.PLATFORM_TERMS_OF_SERVICE,
  `${rootPath}/general-settings/terms-of-service`,
  "Terms Of Service List",

  "White Label",
  // accessPages.WHITE_LABEL_WIZARD_SESSIONS,
  "tenant::todo_tenant",
  `${rootPath}/white-label/sessions`,
  "Wizard Sessions",

  "White Label",
  // accessPages.DATA_SOURCES_DATA_ELEMENT, // only ROOT should have access here
  "tenant::todo_tenant",
  `${rootPath}/white-label/create-brand`,
  "Create Brand",

  "White Label",
  // accessPages.DATA_SOURCES_DATA_ELEMENT, // only ROOT should have access here
  "tenant::todo_tenant",
  `${rootPath}/white-label/domain-administration`,
  "Domain Administration",

  "White Label",
  // accessPages.DATA_SOURCES_DATA_ELEMENT, // only ROOT should have access here
  "tenant::todo_tenant",
  `${rootPath}/white-label/content-management/definitions-list`,
  "Definitions List",

  "White Label",
  // accessPages.DATA_SOURCES_DATA_ELEMENT, // only ROOT should have access here
  "tenant::todo_tenant",
  `${rootPath}/white-label/content-management/templates-list`,
  "Templates List",

  "White Label",
  // accessPages.DATA_SOURCES_DATA_ELEMENT, // only ROOT should have access here
  "tenant::todo_tenant",
  `${rootPath}/white-label/content-management/overrides-list`,
  "Overrides List",

  "White Label",
  // accessPages.DATA_SOURCES_DATA_ELEMENT, // only ROOT should have access here
  "tenant::todo_tenant",
  `${rootPath}/white-label/content-management/deployments-list`,
  "Deployments List",

  "Templatization",
  accessPages.TEMPLATIZATION_ELEMENTS,
  `${rootPath}/templatization/elements`,
  "Elements",

  "Templatization",
  accessPages.TEMPLATIZATION_COMPONENTS,
  `${rootPath}/templatization/components`,
  "Components",

  "Templatization",
  accessPages.TEMPLATIZATION_CSS_VARIABLES,
  `${rootPath}/templatization/css-variables`,
  "CSS Variables",

  "Templatization",
  accessPages.TEMPLATIZATION_COMMON_CSS,
  `${rootPath}/templatization/common-css`,
  "Common SCSS",

  "Templatization",
  accessPages.TEMPLATIZATION_DESIGN_CSS,
  `${rootPath}/templatization/design-css`,
  "Design SCSS",

  "Templatization",
  accessPages.TEMPLATIZATION_SETS_COMPONENTS_DEFAULTS,
  `${rootPath}/templatization/sets-components-defaults`,
  "Components Overrides",

  "Templatization",
  accessPages.TEMPLATIZATION_SETS_COMPONENTS_DATA_DEFAULTS,
  `${rootPath}/templatization/sets-components-data-defaults`,
  "Components Data Overrides",

  "Templatization",
  accessPages.TEMPLATIZATION_SETS_PAGE_META_DEFAULTS,
  `${rootPath}/templatization/sets-page-meta-defaults`,
  "Page Meta Data Overrides",

  "Templatization",
  accessPages.TEMPLATIZATION_COMPONENTS_DATA,
  `${rootPath}/templatization/data-components/`,
  "Components Data",

  "Templatization",
  accessPages.TEMPLATIZATION_SETS_CSS_VARIABLES,
  `${rootPath}/templatization/sets-css-colors/colors`,
  "CSS Sets Colors",

  "Templatization",
  accessPages.TEMPLATIZATION_SETS_CSS_VARIABLES,
  `${rootPath}/templatization/sets-css-spacing/spacing`,
  "CSS Sets Spacing",

  "Templatization",
  accessPages.TEMPLATIZATION_SETS_CSS_VARIABLES,
  `${rootPath}/templatization/sets-css-component-variables/component-variables`,
  "CSS Sets Component Variables",

  "Templatization",
  accessPages.TEMPLATIZATION_SETS_CSS_VARIABLES,
  `${rootPath}/templatization/sets-css-style-border/style-border`,
  "CSS Sets Style Border",

  "Templatization",
  accessPages.TEMPLATIZATION_SETS_CSS_VARIABLES,
  `${rootPath}/templatization/sets-css-style-border-radius/style-border-radius`,
  "CSS Sets Style Border Radius",

  "Templatization",
  accessPages.TEMPLATIZATION_SETS_CSS_VARIABLES,
  `${rootPath}/templatization/sets-css-style-shadow/style-shadow`,
  "CSS Sets Style Shadow",

  "Templatization",
  accessPages.TEMPLATIZATION_SETS_CSS_VARIABLES,
  `${rootPath}/templatization/sets-css-fonts/font`,
  "CSS Sets Fonts",

  "Templatization",
  accessPages.TEMPLATIZATION_SETS_FONTS,
  `${rootPath}/templatization/sets-fonts`,
  "Sets Fonts Loading",

  "Templatization",
  accessPages.TEMPLATIZATION_SETS_CSS_VARIABLES,
  `${rootPath}/templatization/import-tokens`,
  "Import Figma Studio Tokens",

  "Templatization",
  accessPages.TEMPLATIZATION_SETS_PAGES,
  `${rootPath}/templatization/sets-pages`,
  "Sets Pages",

  "Templatization",
  accessPages.TEMPLATIZATION_SETS_POPUPS,
  `${rootPath}/templatization/sets-popups`,
  "Sets Popups",

  "Templatization",
  accessPages.TEMPLATIZATION_EDITOR_THEME_STYLES,
  `${rootPath}/templatization/editor-styles-presets`,
  "Editor Styles Presets",

  "Templatization",
  accessPages.TEMPLATIZATION_CSS_BREAK_POINTS,
  `${rootPath}/templatization/css-break-points`,
  "Break Points",

  "Templatization",
  accessPages.TEMPLATIZATION_DESIGN_SEED,
  `${rootPath}/templatization/design-seeds`,
  "Design Seeds",

  "Templatization",
  accessPages.TEMPLATIZATION_DESIGN_VARIANTS,
  `${rootPath}/templatization/design-variant`,
  "Design Variant",

  "Templatization",
  accessPages.TEMPLATIZATION_SITE_TEMPLATES,
  `${rootPath}/templatization/sites-templates`,
  "Sites Templates",

  "Templatization",
  accessPages.TEMPLATIZATION_BRANDS_SITES,
  `${rootPath}/templatization/brands-sites`,
  "Brands Sites",

  "Templatization",
  accessPages.TEMPLATIZATION_PAGES,
  `${rootPath}/templatization/pages`,
  "Pages",

  "Templatization",
  accessPages.TEMPLATIZATION_POPUPS,
  `${rootPath}/templatization/popups`,
  "Popups",

  "Templatization",
  accessPages.TEMPLATIZATION_PAGE_LAYOUTS,
  `${rootPath}/templatization/layout-pages`,
  "Page Layouts",

  "Jackpots",
  "tenant::todo_tenant",
  // accessPages.DATA_SOURCES_DATA_ELEMENT, // TODO: permission ->  change to a proper permission
  `${rootPath}/jackpot/definitions`,
  "Definitions",

  "Jackpots",
  "tenant::todo_tenant",
  // accessPages.DATA_SOURCES_DATA_ELEMENT, // TODO: permission ->  change to a proper permission
  `${rootPath}/jackpot/sources/list`,
  "Sources List",

  "Jackpots",
  "tenant::todo_tenant",
  // accessPages.DATA_SOURCES_DATA_ELEMENT, // TODO: permission ->  change to a proper permission
  `${rootPath}/jackpot/sources/tokens/list`,
  "Tokens List",

  "Jackpots",
  "tenant::todo_tenant",
  // accessPages.DATA_SOURCES_DATA_ELEMENT, // TODO: permission ->  change to a proper permission
  `${rootPath}/jackpot/sources/security-tokens/list`,
  "Security Tokens List",

  "Jackpots",
  "tenant::todo_tenant",
  // accessPages.DATA_SOURCES_DATA_ELEMENT, // TODO: permission ->  change to a proper permission
  `${rootPath}/jackpot/win-history/list`,
  "Win History",

  "Jackpots",
  "tenant::todo_tenant",
  // accessPages.DATA_SOURCES_DATA_ELEMENT, // TODO: permission ->  change to a proper permission
  `${rootPath}/jackpot/jackpot/setup`,
  "Setup",

  "Jackpots",
  "tenant::todo_tenant",
  // accessPages.DATA_SOURCES_DATA_ELEMENT, // TODO: permission ->  change to a proper permission
  `${rootPath}/jackpot/my-profile/list`,
  "My Profile",

  "Casino",
  accessPages.DATA_SOURCES_DATA_ELEMENT,
  `${rootPath}/casino/games-data-sources`,
  "Games Data Sources",

  "Digitain",
  accessPages.DIGITAIN_TICKETS,
  `${rootPath}/digitain/opened-tickets`,
  "Opened Tickets",

  "Digitain",
  accessPages.DIGITAIN_PLAYERS,
  `${rootPath}/digitain/players`,
  "Players",

  "Digitain",
  accessPages.DIGITAIN_TICKETS,
  `${rootPath}/digitain/tickets`,
  "Tickets",

  "Digitain",
  accessPages.DIGITAIN_TICKETS,
  `${rootPath}/digitain/transactions`,
  "Transactions",

  "Digitain WF",
  accessPages.DIGITAIN_PLAYERS,
  `${rootPath}/digitain-wf/players`,
  "Players",

  "Digitain WF",
  accessPages.DIGITAIN_TICKETS,
  `${rootPath}/digitain-wf/tickets`,
  "Tickets",

  "Digitain WF",
  accessPages.DIGITAIN_TICKETS,
  `${rootPath}/digitain-wf/transactions`,
  "Transactions",

  "Player Notifications",
  accessPages.NOTIFICATION_TEMPLATE,
  `${rootPath}/player-notifications/list`,
  "Notifications",

  "Player Notifications",
  accessPages.NOTIFICATION_TEMPLATE,
  `${rootPath}/player-notifications/templates`,
  "Notification Templates",

  "Main Settings",
  accessPages.TENANT_MAIN_SETTINGS,
  `${rootPath}/main-settings`,
  "Main Settings",

  "SMS Codes",
  "tenant::todo_tenant", // TODO: permission ->  change to a proper permission
  `${rootPath}/sms-codes/winner-sms-validation`,
  "SMS Validation [Winner]",

  "SMS Codes",
  "tenant::todo_tenant", // TODO: permission ->  change to a proper permission
  `${rootPath}/sms-codes/wl-sms-validation`,
  "SMS Validation [WL]",

  "SMS Codes",
  "tenant::todo_tenant", // TODO: permission ->  change to a proper permission
  `${rootPath}/sms-codes/sms-auth-code`,
  "SMS Auth Codes",

  "Payments",
  accessPages.PAYMENTS_BRIDGERPAY_PAYMENT_MTHODS_ORDER_LIST,
  `${rootPath}/payments/payment-methods`,
  "BridgerPay - Payment Methods",

  "Income Access",
  accessPages.INCOME_ACCESS_BACKUP_FILES,
  `${rootPath}/income-access/backup-files`,
  "Backup Files",

  "Income Access",
  accessPages.INCOME_ACCESS_UPLOAD_LOGS,
  `${rootPath}/income-access/upload-log`,
  "Upload Log",

  "Admin Settings",
  "tenant::todo_tenant", // TODO: permission ->  change to a proper permission
  `${rootPath}/customer-search-settings/0`,
  "Customer Search Settings",
];

const genCmd = url => () => {
  getHistory().push(url);
};
const commands = [];

for (let i = 0; i < pages.length; i += 4) {
  const group = pages[i];
  const access = pages[i + 1];
  const url = pages[i + 2];
  const name = pages[i + 3];

  commands.push({
    id: i + 1,
    access: access,
    category: "Navigate",
    group: group,
    name: name,
    url: url,
    command: genCmd(url),
  });
}

//console.log("commands", commands);

const renderCommand = suggestion => {
  const { name, highlight = [], category, shortcut, group } = suggestion;

  // handle simple highlight when searching a single key
  if (!Array.isArray(highlight)) {
    return (
      <span>
        <span className={`atom-category ${category}`}>{category}</span>
        {!!group && <span>{group} -&nbsp;</span>}
        <span dangerouslySetInnerHTML={{ __html: highlight || name }} />
        <kbd className="atom-shortcut">{shortcut}</kbd>
      </span>
    );
  }

  return (
    <span className="atom-title">
      <span className={`atom-category ${category}`}>{category}</span>
      {!!group && (
        <span>
          <span dangerouslySetInnerHTML={{ __html: highlight[1] || group }} />
          &nbsp;-&nbsp;
        </span>
      )}
      <span dangerouslySetInnerHTML={{ __html: highlight[0] || name }} />
      <kbd className="atom-shortcut">{shortcut}</kbd>
    </span>
  );
};

const theme = {
  container: "atom-container",
  containerOpen: "atom-containerOpen",
  content: "atom-content",
  header: "atom-header",
  input: "atom-input",
  inputFocused: "atom-inputFocused",
  inputOpen: "atom-inputOpen",
  modal: "atom-modal",
  overlay: "atom-overlay",
  spinner: "atom-spinner",
  suggestion: "atom-suggestion",
  suggestionFirst: "atom-suggestionFirst",
  suggestionHighlighted: "atom-suggestionHighlighted",
  suggestionsContainer: "atom-suggestionsContainer",
  suggestionsContainerOpen: "atom-suggestionsContainerOpen",
  suggestionsList: "atom-suggestionsList",
  trigger: "atom-trigger",
};

const options = {
  allowTypo: true,
  key: "name",
  keys: ["name", "group"],
  limit: 100,
  scoreFn: null,
  threshold: -Infinity,
};

const updateTenant = (allComands, currentTenant) => {
  const processComands = allComands.map(elm => {
    const item = { ...elm };
    if (item && item.url && item.url.indexOf("[CURRENT-TENANT]") !== -1) {
      item.url = item.url.replace("[CURRENT-TENANT]", currentTenant);
      item.command = genCmd(item.url);
    }
    return item;
  });
  return processComands;
};

const CommandPalette = ({ currentTenant }) => {
  const { t } = useTranslation();

  const [state, setState] = React.useState(updateTenant(commands, currentTenant));

  React.useEffect(() => {
    setState(v => {
      return updateTenant(commands, currentTenant);
    });
  }, [currentTenant]);

  const closePalette = () => {
    try {
      setTimeout(() => {
        document.querySelector(".atom-overlay")?.click();
      }, 0);
    } catch (err) {
      /**/
    }
  };

  const filteredCommands = state.filter(c => {
    let result = false;
    if (typeof c.access === "string") {
      if (!c.access.includes("::")) {
        const opAccess = operationsAccess(c.access);
        if (opAccess.canList) result = true;
      } else {
        result = hasAclAccess(c.access);
      }
    } else if (Array.isArray(c.access)) {
      let tmp = true;
      c.access.forEach(access => {
        if (!access.includes("::")) {
          const opAccess = operationsAccess(access);
          if (!opAccess.canList) tmp = false;
        } else {
          if (!hasAclAccess(access)) {
            tmp = false;
          }
        }
      });
      result = tmp;
    }

    return result;
  });

  const header = (
    <React.Fragment>
      <div className="nav-bar">
        <div>
          <div className="tenant">Search pages</div>
          <div className="full"></div>
          <div className="close" onClick={closePalette}>
            <X className="ficon" />
          </div>
        </div>
      </div>
      <div className="atom-info">
        <div>
          <span className="atom-pr">{t("Search")}</span>
          <span className="atom-pr">
            <kbd className="atom-kbd">↑↓</kbd> {t("to navigate")}
          </span>
          <span className="atom-pr">
            <kbd className="atom-kbd">enter</kbd> {t("to select")}
          </span>
          <span className="atom-pr">
            <kbd className="atom-kbd">esc</kbd> {t("to dismiss")}
          </span>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <CommandPaletteBase
      renderCommand={renderCommand}
      alwaysRenderCommands
      closeOnSelect={true}
      commands={filteredCommands}
      defaultInputValue=""
      display="modal"
      header={header}
      highlightFirstSuggestion
      hotKeys="shift+alt+/"
      maxDisplayed={100}
      options={options}
      placeholder={t("Search for page")}
      reactModalParentSelector="body"
      resetInputOnOpen={true}
      shouldReturnFocusAfterClose
      theme={theme}
      onAfterOpen={() => {
        setTimeout(() => {
          document.querySelector(".ReactModal__Content.atom-modal")?.classList.add("ReactModal__Content--did-open");
        }, 0);
      }}
      trigger={
        <div className="ficon" title={t("Press 'shift+alt+/' to search pages")}>
          <Search />
        </div>
      }
    />
  );
};

const mapStateToProps = state => {
  return {
    currentTenant: state.tenants.currentTenant,
  };
};

export default connect(mapStateToProps)(CommandPalette);
