import { produce } from "immer";
import { selectsConstants } from "../constants";

const initialState = {
  currencies: [],
  payment_methods: [],
  platform_types: [],
  products: [],
  sub_products: [],
  bonus_trigger_types: [],
  bonus_campaign: [],
  redeem_as: [
    { id: 1, name: "Bonus" },
    { id: 2, name: "Cash" }
  ],
  client_sites: [],
  currency_policies: [],
  currency_codes: [],
  prize_templates: [],
};

const selectsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case selectsConstants.LOADED: {

        if (typeof action.data.currencies !== "undefined") draft.currencies = action.data.currencies;
        if (typeof action.data.payment_methods !== "undefined") draft.payment_methods = action.data.payment_methods;
        if (typeof action.data.platform_types !== "undefined") draft.platform_types = action.data.platform_types;
        if (typeof action.data.products !== "undefined") draft.products = action.data.products;
        if (typeof action.data.sub_products !== "undefined") draft.sub_products = action.data.sub_products;
        if (typeof action.data.bonus_trigger_types !== "undefined") draft.bonus_trigger_types = action.data.bonus_trigger_types;
        if (typeof action.data.bonus_campaign !== "undefined") draft.bonus_campaign = action.data.bonus_campaign;
        if (typeof action.data.client_sites !== "undefined") draft.client_sites = action.data.client_sites;
        if (typeof action.data.currency_policies !== "undefined") draft.currency_policies = action.data.currency_policies;
        if (typeof action.data.currency_codes !== "undefined") draft.currency_codes = action.data.currency_codes;
        if (typeof action.data.prize_templates !== "undefined") draft.prize_templates = action.data.prize_templates;

        break;
      }
      default:
        /* noop */
        break;
    }
  });

export default selectsReducer;